var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"slot":"subview-content","column":""},slot:"subview-content"},[(!_vm.hideToolBar)?_c('v-layout',{class:_vm.$vuetify.breakpoint.mdAndUp ? '' : 'wrap',attrs:{"align-center":""}},[_c('v-layout',{class:_vm.$vuetify.breakpoint.xsOnly && 'column',attrs:{"align-center":""}},[_c('v-layout',{class:_vm.$vuetify.breakpoint.xsOnly && 'justify-center'},[_c('div',{staticClass:"text-h6 primary--text"},[_vm._v(_vm._s(_vm.title))])]),(!!_vm.$slots['header-items'])?_c('v-layout',{class:_vm.$vuetify.breakpoint.xsOnly && 'mt-4 justify-center'},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-spacer'):_vm._e(),_c('v-layout',{attrs:{"shrink":""}},[_vm._t("header-items")],2)],1):_vm._e()],1),_c('v-layout',{class:[
        _vm.$vuetify.breakpoint.mdAndUp && 'shrink',
        _vm.$vuetify.breakpoint.xsOnly
          ? 'wrap justify-center mb-2'
          : 'justify-end' ]},[_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideRowsPerPage),expression:"!hideRowsPerPage"}],class:[_vm.$vuetify.breakpoint.smAndDown && 'mt-3'],attrs:{"shrink":"","align-center":"","justify-end":""}},[_c('div',{class:['body-2 black--text']},[_vm._v("Wyników na stronie:")]),_c('v-select',{staticClass:"default-input-style zg-dt-select-style ml-2 zg-dt-select",attrs:{"mandatory":"","items":_vm.rowsPerPageItems,"hide-details":true,"outlined":"","menu-props":{
            contentClass: 'zg-select-border-radius',
          },"append-icon":"fa-chevron-down","clear-icon":"mdi-close","dense":""},model:{value:(_vm.dataRowsPerPage),callback:function ($$v) {_vm.dataRowsPerPage=$$v},expression:"dataRowsPerPage"}})],1),_c('v-layout',{class:[
          _vm.$vuetify.breakpoint.smAndDown && 'mt-3',
          !_vm.$vuetify.breakpoint.xsOnly && 'shrink ml-4' ],attrs:{"align-center":"","justify-center":""}},[(_vm.showSearch)?_c('v-text-field',{staticClass:"default-input-style zg-dt-search-style zg-dt-select mr-2",attrs:{"prepend-inner-icon":"fas fa-search","hide-details":true,"outlined":"","clearable":"","placeholder":"Wyszukaj..."},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"20"}},[_vm._v("fas fa-search")])]},proxy:true}],null,false,2038019050),model:{value:(_vm.internalSearch),callback:function ($$v) {_vm.internalSearch=$$v},expression:"internalSearch"}}):_vm._e(),(_vm.showAddButton)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({attrs:{"shrink":""}},'v-layout',attrs,false),on),[_c('DefaultButton',{staticClass:"pa-0 ma-0",attrs:{"text":"","icon":"","small":"","bgColor":"expressive_green"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('add')}}},[_c('v-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"small":"","outline":""}},[_vm._v("fas fa-plus")])],1)],1)]}}],null,false,2242996754)},[_c('span',[_vm._v(_vm._s(_vm.addTooltip))])]):_vm._e()],1)],1)],1):_vm._e(),_c('v-data-table',{class:['zg-datatable-bg data-table-scroll', _vm.stripped ? 'stripped' : ''],attrs:{"hideDefaultFooter":true,"no-data-text":_vm.noDataText,"items":_vm.objects,"headers":_vm.headers,"options":_vm.bridgePaginationOptions,"serverItemsLength":_vm.totalItems,"must-sort":true,"loading":_vm.loading,"custom-sort":_vm.customSort,"item-class":_vm.rowClass,"hide-default-header":"","loader-height":"2","loading-text":"Pobieram...","mobile-breakpoint":"0","fixed-header":_vm.fixedHeader,"height":_vm.customHeight},on:{"update:options":function($event){_vm.bridgePaginationOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.text,class:[
              'column',
              header.sortable ? 'sortable' : '',
              _vm.pagination.descending ? 'desc' : 'asc',
              header.value === _vm.pagination.sortBy && header.sortable
                ? 'active'
                : '',
              _vm.headerPadding ],style:({ textAlign: header.align ? header.align : 'center' }),on:{"click":function($event){return _vm.changeSort(header.value, header.sortable)}}},[_c('v-layout',{attrs:{"align-center":""}},[_c('span',{class:[
                  'zg-table-header text-no-wrap text-uppercase',
                  _vm.headerMargin ? 'mx-2' : '',
                  header.sortable ? 
                  header.value === _vm.pagination.sortBy
                    ? 'font-weight-bold primary--text'
                    : 'disabled-item--text'
                    : 'secondary--text' ]},[_vm._v(_vm._s(header.text))]),(header.sortable && header.value === _vm.pagination.sortBy)?_c('v-icon',{staticClass:"mx-1",attrs:{"size":"18px !important","color":header.sortable ? 
                  header.value === _vm.pagination.sortBy
                    ? 'primary'
                    : 'disabled-item'
                    : 'secondary'}},[_vm._v(" "+_vm._s(_vm.pagination.descending && header.value === _vm.pagination.sortBy ? "mdi-chevron-up" : "mdi-chevron-down"))]):_vm._e()],1)],1)}),0)])]}},{key:"item",fn:function(objects){return [_c('tr',{class:_vm.rowClass && _vm.rowClass(objects.item),on:{"click":function($event){return _vm.$emit('details', objects.item)}}},[_vm._l((_vm.headers),function(header){return [_vm._t("cell",function(){return [_c(_vm.cellFactory(header, objects),{tag:"component",staticStyle:{"text-decoration":"none !important"},attrs:{"value":_vm.cellValueFactory(header, objects),"header":header,"item":objects.item},on:{"clicked":_vm.handleClickedCell}})]},{"header":header,"objects":objects})]})],2)]}},(_vm.showSummary)?{key:"body.append",fn:function(){return [_c('tr',{staticStyle:{"background-color":"#e9e9e9"}},[_vm._l((_vm.headers),function(header){return [_vm._t("summary",null,{"header":header})]})],2)]},proxy:true}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }