<template>
  <v-layout column slot="subview-content">
    <v-layout
      v-if="!hideToolBar"
      align-center
      :class="$vuetify.breakpoint.mdAndUp ? '' : 'wrap'"
    >
      <v-layout align-center :class="$vuetify.breakpoint.xsOnly && 'column'">
        <v-layout :class="$vuetify.breakpoint.xsOnly && 'justify-center'">
          <div class="text-h6 primary--text">{{ title }}</div>
        </v-layout>
        <v-layout
          v-if="!!$slots['header-items']"
          :class="$vuetify.breakpoint.xsOnly && 'mt-4 justify-center'"
        >
          <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
          <v-layout shrink>
            <slot name="header-items"></slot>
          </v-layout>
        </v-layout>
      </v-layout>
      <v-layout
        :class="[
          $vuetify.breakpoint.mdAndUp && 'shrink',
          $vuetify.breakpoint.xsOnly
            ? 'wrap justify-center mb-2'
            : 'justify-end',
        ]"
      >
        <v-layout
          shrink
          align-center
          justify-end
          :class="[$vuetify.breakpoint.smAndDown && 'mt-3']"
          v-show="!hideRowsPerPage"
        >
          <div :class="['body-2 black--text']">Wyników na stronie:</div>
          <v-select
            mandatory
            v-model="dataRowsPerPage"
            :items="rowsPerPageItems"
            :hide-details="true"
            outlined
            class="default-input-style zg-dt-select-style ml-2 zg-dt-select"
            :menu-props="{
              contentClass: 'zg-select-border-radius',
            }"
            append-icon="fa-chevron-down"
            clear-icon="mdi-close"
            dense
          ></v-select>
        </v-layout>
        <v-layout
          align-center
          justify-center
          :class="[
            $vuetify.breakpoint.smAndDown && 'mt-3',
            !$vuetify.breakpoint.xsOnly && 'shrink ml-4',
          ]"
        >
          <v-text-field
            v-if="showSearch"
            class="default-input-style zg-dt-search-style zg-dt-select mr-2"
            prepend-inner-icon="fas fa-search"
            :hide-details="true"
            outlined
            clearable
            placeholder="Wyszukaj..."
            v-model="internalSearch"
          >
            <template v-slot:prepend-inner>
              <v-icon size="20">fas fa-search</v-icon>
            </template>
          </v-text-field>
          <v-tooltip v-if="showAddButton" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-layout shrink v-on="on" v-bind="attrs">
                <DefaultButton
                  text
                  icon
                  small
                  bgColor="expressive_green"
                  class="pa-0 ma-0"
                  @click.prevent="$emit('add')"
                >
                  <v-icon small style="vertical-align: middle" outline
                    >fas fa-plus</v-icon
                  >
                </DefaultButton>
              </v-layout>
            </template>
            <span>{{ addTooltip }}</span>
          </v-tooltip>
        </v-layout>
      </v-layout>
    </v-layout>
    <v-data-table
      :hideDefaultFooter="true"
      :no-data-text="noDataText"
      :items="objects"
      :headers="headers"
      :options.sync="bridgePaginationOptions"
      :serverItemsLength="totalItems"
      :must-sort="true"
      :loading="loading"
      :class="['zg-datatable-bg data-table-scroll', stripped ? 'stripped' : '']"
      :custom-sort="customSort"
      :item-class="rowClass"
      hide-default-header
      loader-height="2"
      loading-text="Pobieram..."
      mobile-breakpoint="0"
      :fixed-header="fixedHeader"
      :height="customHeight"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="header in headers"
              :key="header.text"
              :style="{ textAlign: header.align ? header.align : 'center' }"
              :class="[
                'column',
                header.sortable ? 'sortable' : '',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy && header.sortable
                  ? 'active'
                  : '',
                headerPadding,
              ]"
              @click="changeSort(header.value, header.sortable)"
            >
              <v-layout align-center>
                <span
                  :class="[
                    'zg-table-header text-no-wrap text-uppercase',
                    headerMargin ? 'mx-2' : '',
                    header.sortable ? 
                    header.value === pagination.sortBy
                      ? 'font-weight-bold primary--text'
                      : 'disabled-item--text'
                      : 'secondary--text',
                  ]"
                  >{{ header.text }}</span
                >
                <v-icon
                  v-if="header.sortable && header.value === pagination.sortBy"
                  size="18px !important"
                  class="mx-1"
                  :color="
                    header.sortable ? 
                    header.value === pagination.sortBy
                      ? 'primary'
                      : 'disabled-item'
                      : 'secondary'
                  "
                >
                  {{
                    pagination.descending && header.value === pagination.sortBy
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}</v-icon
                >
              </v-layout>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="objects">
        <tr
          @click="$emit('details', objects.item)"
          :class="rowClass && rowClass(objects.item)"
        >
          <template v-for="header in headers">
            <slot name="cell" v-bind:header="header" v-bind:objects="objects">
              <component
                style="text-decoration: none !important"
                :is="cellFactory(header, objects)"
                :value="cellValueFactory(header, objects)"
                :header="header"
                :item="objects.item"
                @clicked="handleClickedCell"
              ></component>
            </slot>
          </template>
        </tr>
      </template>
      <template v-if="showSummary" v-slot:[`body.append`]="">
        <tr style="background-color: #e9e9e9">
          <template v-for="header in headers">
            <slot name="summary" v-bind:header="header"></slot>
          </template>
        </tr>
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
export default {
  props: {
    title: {},
    addTooltip: {},
    showAddButton: {
      default: true,
    },
    noDataText: {
      default: "Brak danych",
    },
    objects: {},
    headers: {},
    pagination: {},
    totalItems: {},
    loading: {},
    messageType: {},
    message: {},
    showMessage: {},
    hideToolBar: {
      type: Boolean,
      default: false,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    customSort: {
      default: undefined,
    },
    search: {},
    stripped: {
      type: Boolean,
      default: false,
    },
    headerMargin: {
      type: Boolean,
      default: false,
    },
    headerPadding: {
      type: String,
    },
    rowClass: {
      default: undefined,
    },
    hideRowsPerPage: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    customHeight: {
      type: String,
      default: undefined
    },
    fixedHeader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rowsPerPageItems: [1, 10, 20, 30, 40, 50, 100, 200],
    };
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    DefaultCell: () => import("@/components/tables/DefaultCell"),
    DefaultClickableCell: () =>
      import("@/components/tables/DefaultClickableCell"),
  },
  computed: {
    bridgePaginationOptions: {
      get() {
        let bridge = {
          page: this.pagination.page,
          itemsPerPage: this.pagination.rowsPerPage,
          sortBy: [this.pagination.sortBy],
          sortDesc: [this.pagination.descending],
        };
        return bridge;
      },
      set(value) {
        let pagination = this.pagination;
        pagination.page = value.page;
        pagination.rowsPerPage = value.itemsPerPage;
        pagination.sortBy = value.sortBy[0];
        pagination.descending = value.sortDesc[0];
        this.$emit("update:pagination", pagination);
      },
    },
    dataPagination: {
      get() {
        return this.pagination;
      },
      set(value) {
        this.$emit("update:pagination", value);
      },
    },
    internalSearch: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("update:search", value);
      },
    },
    totalPages() {
      if (this.totalItems == null || this.dataPagination.rowsPerPage == null)
        return 0;

      return Math.ceil(this.totalItems / this.dataPagination.rowsPerPage);
    },
    dataRowsPerPage: {
      get() {
        return this.dataPagination.rowsPerPage;
      },
      set(value) {
        this.dataPagination.rowsPerPage = value;
        if (this.dataPagination.page > this.totalPages) {
          this.dataPagination.page = 1;
        }
      },
    },
  },
  methods: {
    nextPage() {
      if (this.dataPagination.page + 1 <= this.totalPages) {
        this.dataPagination.page++;
      }
    },
    previousPage() {
      if (this.dataPagination.page - 1 >= 1) {
        this.dataPagination.page--;
      }
    },
    changeSort(column, sortable) {
      if (!sortable) {
        return;
      }

      if (this.dataPagination.sortBy === column) {
        this.dataPagination.descending = !this.dataPagination.descending;
      } else {
        this.dataPagination.sortBy = column;
        this.dataPagination.descending = false;
      }
      this.dataPagination.page = 1;
    },
    cellFactory(header) {
      switch (header.text) {
        case "Dane":
          return "DefaultClickableCell";
        default:
          return "DefaultCell";
      }
    },
    cellValueFactory(header) {
      switch (header.text) {
        case "Dane":
          return "Szczegóły";
        default:
          return "";
      }
    },
    handleClickedCell(header, item) {
      this.$emit("details", item);
      this.$emit("clicked:cell", header, item);
    },
  },
};
</script>

<style lang="scss">
.zg-dt-select-style {
  min-width: 50px !important;
  max-width: 90px !important;
}
.zg-dt-select .v-input__slot {
  margin-top: 4px !important;
}
.zg-dt-search-style {
  max-width: 300px;
}
.zg-datatable-bg {
  background-color: transparent !important;
}
.zg-datatable-bg .v-table__overflow {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch !important;
}
.zg-datatable-bg ::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}
.zg-datatable-bg ::-webkit-scrollbar-track {
  background: white; /* color of the tracking area */
}
.zg-datatable-bg ::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 4px solid white; /* creates padding around scroll thumb */
}
.stripped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.marked-row {
  background-color: #f5fafe;
}
td u {
  text-decoration: none;
}
.zg-select-data-table-border-radius {
  border-radius: 8px !important;
  border-width: 15px 0px !important;
  border-style: solid;
  border-color: #fff;
  & .v-list__tile__title {
    font-size: 14px !important;
  }
}
.zg-table-header {
  font-size: 10px;
  font-weight: 400;
}
</style>
